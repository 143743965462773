<template>
  <div>
    <page-title :color="color" v-if="title">
      <template v-slot:left>{{ title }}</template>
    </page-title>

    <div class="not-exists-answer" v-if="isNotExistsAnswer">
      <div class="text">
        ※ADHDセルフ問診は受診していません
      </div>
    </div>
    <div v-else>
      <div
        v-for="(question, index) in questions"
        :key="question.id"
        :class="{ more: hasMore(index), last: isLast(index) }"
      >
        <div class="question">
          <div class="display-flex">
            <div class="no-text" :class="color">Q{{ question.id }}</div>
            <div class="question-text">
              {{ question.text }}
            </div>
          </div>
        </div>

        <div class="answer-check">
          <div class="border" />
          <div class="display-flex answers">
            <div
              class="icon"
              v-for="choice in question.choices"
              :key="`${choice}_${question.id}`"
            >
              <v-icon
                :color="fillColor(color)"
                :size="36"
                v-if="choice === question.choices[answers[index]]"
                >check_circle</v-icon
              >
              <v-icon color="#E3E6E6" :size="36" v-else
                >check_circle_outline</v-icon
              >
            </div>
          </div>
        </div>

        <!-- 0       2       4 -->
        <!-- 回答の選択肢を上記のように表示するため、偶数部分だけ取り出している -->
        <div class="display-flex choice">
          <span
            class="text"
            :class="color"
            v-for="choice in questionBoxChoiceTexts(question.choices)"
            :key="`${choice}_${question.id}`"
          >
            {{ choice }}
          </span>
        </div>

        <hr />
      </div>
      <div
        id="more-area"
        @click="isAllAnswerDisplay = !isAllAnswerDisplay"
        v-if="isAllAnswerDisplay === false && displayNum < questions.length"
      >
        全ての回答を見る
        <v-icon :size="16">keyboard_arrow_down</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import AdhdQuestion from "@/types/adhd";
import PageTitle from "./PageTitle.vue";
export default {
  name: "AnswerResult",
  data() {
    return {
      isAllAnswerDisplay: false
    };
  },
  props: {
    questions: {
      type: Array[AdhdQuestion],
      required: true
    },
    answers: {
      type: Array[Number],
      required: true
    },
    color: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    displayNum: {
      type: Number,
      required: false,
      defalut: undefined
    }
  },
  components: { PageTitle },
  computed: {
    isNotExistsAnswer() {
      return this.answers[0] !== 0 && !this.answers[0];
    }
  },
  methods: {
    hasMore(index) {
      return (
        this.isAllAnswerDisplay === false &&
        this.displayNum &&
        this.displayNum <= index
      );
    },
    isLast(index) {
      return this.isAllAnswerDisplay === false && this.displayNum === index + 1;
    },
    questionBoxChoiceTexts(choices) {
      return choices.filter((_, i) => i % 2 == 0);
    },
    fillColor(color) {
      if (color == "green") {
        return "#28c8be";
      } else if (color == "blue") {
        return "#0bbdd6";
      }
      return "#ffffff";
    }
  }
};
</script>

<style scope>
@import "../../assets/style/adhd-color.css";

.more {
  display: none;
}
.last {
  position: relative;
}
.last:before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(rgba(255, 255, 255, 0) 0, #fff 100%);
  position: absolute;
  top: 0;
  left: 0;
}

div .not-exists-answer {
  margin: 20px 16px 20px 16px;
  background-color: #f8f8f8;
  border-radius: 5px;
}
div .not-exists-answer .text {
  text-align: center;
  padding: 24px 0px;
}

.question {
  margin: 16px;
}
.question .no-text {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
}
.question .question-text {
  padding-left: 12px;
  font-size: 13px;
  font-weight: 700;
  text-align: left;
}
.answer-check {
  position: relative;
}
.answer-check .border {
  margin-left: 64px;
  position: absolute;
  height: 3px;
  background-color: rgb(227, 230, 230);
  width: calc(100% - 128px);
  top: calc(50%);
}
.answers {
  position: relative;
  flex-direction: row-reverse;
  justify-content: space-around;
  padding: 12px 30px;
}
.answers .icon {
  background-color: #ffffff;
}
.choice {
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 0px 32px 0px 42px;
}
.choice .text {
  font-size: 13px;
  font-weight: 700;
}
#more-area {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
hr {
  margin: 16px;
}

@media (hover: hover) {
  #more-area:hover {
    cursor: pointer;
  }
}
</style>
