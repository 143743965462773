








import CommonButton from "./CommonButton.vue";
export default {
  name: "LinkButton",
  props: {
    link: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "normal",
      required: false
    },
    backgroundColor: {
      type: String,
      default: "normal",
      required: false
    },
    isBlank: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  components: { CommonButton }
};
