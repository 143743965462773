<template>
  <button :class="[`back-${backgroundColor}`, color]" @click="onClick()">
    <div class="button-label" :class="color">
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: "CommonButton",
  props: {
    color: {
      type: String,
      default: "normal",
      required: false
    },
    backgroundColor: {
      type: String,
      default: "normal",
      required: false
    }
  },
  methods: {
    onClick() {
      this.$emit("click");
    }
  }
};
</script>

<style scoped>
@import "../../assets/style/adhd-color.css";

button {
  width: 100%;
  border-radius: 5px;
}

button .button-label {
  margin: 12px;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
}
</style>
