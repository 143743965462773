import rootStore from "@/store";

export const urlMixin = {
  computed: {
    askQAUrl() {
      const params = new URLSearchParams();
      // computedは() => stringとして扱われてそのままでは読めないので、raw stringでstringに変換しています
      params.set("result_url", `${this.answerResultUrl}`);
      // eslint-disable-next-line
      const state: any = rootStore.state;
      // 途中の回答を送る場合はanswerIdが残ってるので、それで送信する(最終回答後にリセットしている)
      if (state.adhd.answerId) {
        params.set("answer_id", state.adhd.answerId);
      } else if (state.adhd.lastAnswerId) {
        params.set("answer_id", state.adhd.lastAnswerId);
      }

      const url = new URL(
        "adult_adhd/topics/new",
        process.env.VUE_APP_API_BASE_URL
      );
      url.search = params.toString();

      return url.toString();
    },
    answerParam() {
      // eslint-disable-next-line
      const state: any = rootStore.state;
      return {
        answer: {
          data: {
            version: "1.0",
            a: state.adhd.screeningAnswersApart,
            b: state.adhd.screeningAnswersBpart
          }
        }
      };
    },
    answerParamUrl() {
      return encodeURIComponent(JSON.stringify(this.answerParam));
    },
    answerResultUrl() {
      const params = new URLSearchParams();
      params.set("answers", `${this.answerParamUrl}`);

      const url = new URL("adhd/answer_result", process.env.VUE_APP_BASE_URL);
      url.search = params.toString();

      return url.toString();
    }
  }
};
